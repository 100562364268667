import ViewAndDownload from "./ViewAndDownload";
import Loading from "./Loading";
import { useEffect, useState } from "react";
import Notfound from "./Notfound";
import axiosClient from "../../lib/axiosClient";
import { hashRouter } from "../../Helpers/useHashRouter";
import { useParams } from "react-router-dom";
import { isNull } from "../../Helpers/utils";



export default function Item() {

    // get & set sanctum tokens
    const csrf = () => axiosClient.get('sanctum/csrf-cookie');


    const params = useParams()
    const { slug } = params;

    const [isLoading, setIsLoading] = useState(true)
    const [notFound, setNotfound] = useState(false)

    const [itemData, setItemData] = useState({})

    useEffect(() => {
        const getItemData = async (slug) => {
            try {
                setIsLoading(true);
                setNotfound(false);

                await csrf();

                const res = await axiosClient.get(hashRouter('getItemBySlug') + "/" + slug);
                const { status, data } = res?.data;

                // console.log(data);

                if (status) {
                    setItemData(data);
                }

            } catch (error) {
                // console.log(error);

                if (error?.response?.data?.status === false && error?.response?.data?.message === "Not found!") {
                    setNotfound(true);
                }

            } finally {
                setIsLoading(false);
            }
        };

        if (!isNull(slug)) {
            getItemData(slug);
        }
    }, [slug]);



    return (
        <>
            {isLoading ? <Loading /> :
                notFound ? <Notfound /> :
                    <>
                        <ViewAndDownload data={itemData} />
                    </>
            }
        </>
    );
}