import { useState } from "react";
import { LoaderIcon02 } from "../../db/icons";
import { isNull } from "../../Helpers/utils";
import { checkItemPass } from "../../lib/api";
import ModalLayout from "../../layouts/ModalLayout";


export default function PasswordForm({ close, download, handleOpenNormalItem, type, token }) {

    const [password, setPassword] = useState("")
    const [inProgress, setInProgress] = useState(false)

    const [passwordError, setPasswordError] = useState(false)

    const checkPass = async () => {

        setPasswordError(false)

        if (isNull(password)) {
            setPasswordError(true)
            return;
        }

        setInProgress(true)

        const { status } = await checkItemPass({ token, password });

        if (status) {
            close()

            if (['image', 'video', 'audio', 'document'].includes(type)) {
                download()
            } else if (['link', 'text'].includes(type)) {
                handleOpenNormalItem()
            }
        } else {
            setPasswordError(true)
        }

        setInProgress(false)
    }

    const buttonName = type === "link" ? "Unlock and Visit" : type === "text" ? "Unlock and View" : "Unlock and Download";

    return (
        <ModalLayout
            wallBackground="bg-black/60 max-lg:bg-black/80"
            className={"fixed lg:max-w-xl w-full -translate-x-2/4 lg:-translate-y-2/4 left-2/4 lg:top-2/4 max-lg:bottom-0 z-[11013] max-lg:p-4"}
            close={close}
        >
            <div className="bg-white w-full rounded-lg lg:p-6 p-4">

                <div className="flex items-center justify-between">
                    <h2 className="textHeader-m">Enter Password</h2>
                    <button onClick={close} className="hover:scale-110 transition-all duration-300 ease-in-out">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </button>
                </div>

                <div className="mt-8">
                    <div>
                        <label for="password" className="block text-secondary/60 font-medium">{`Please enter the password to download the ${type}:`}</label>
                        <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" id="password" className={`mt-2 p-3 border rounded-md w-full outline-none ${passwordError ? 'border-red-500' : 'focus:ring-1 focus:border-primary'}`} placeholder="Enter password" />
                        <p class="textBody-xs3 text-secondary/50 mt-1">The password was provided by the file owner.</p>
                    </div>

                    <div className="mt-10 flex max-lg:flex-col-reverse justify-end lg:gap-4 gap-2">
                        <button
                            disabled={inProgress}
                            onClick={close}
                            type="button"
                            className="px-8 py-3.5 textBody-m2 border bg-transparent text-currentColor rounded-lg shadow-sm hover:bg-opacity-90 transition duration-300 outline-none">
                            Cancel
                        </button>
                        <button
                            disabled={inProgress}
                            onClick={checkPass}
                            type="button"
                            className="px-8 py-3.5 textBody-m2 border border-primary bg-primary text-white rounded-lg shadow-sm hover:bg-primary/90 transition duration-300 outline-none">
                            {inProgress ? <LoaderIcon02 className="w-6 h-6 mx-auto" /> : buttonName}
                        </button>
                    </div>
                </div>
            </div>
        </ModalLayout>
    )
}