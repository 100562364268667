import { NotFoundIcon } from "../../db/icons";


export default function Notfound() {
    return (
        <div className="bg-white flex flex-col gap-2 items-center text-center justify-center min-h-screen px-2">
            <NotFoundIcon className="w-20 h-20" />
            <h1 className="lg:textHeader-xl textHeader-l">That page can't be found.</h1>
            <p className="text-secondary/60">Oops! The page you're looking for doesn't exist or has been moved.</p>
        </div>
    )
}