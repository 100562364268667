import { useState } from "react";
import PasswordForm from "./PasswordForm";
import ToDownload from "./ToDownload";
import ToView from "./ToView";
import { openNormalItem } from "../../lib/api";
import { isNull } from "../../Helpers/utils";
import { download } from "../../lib/downloadFiles";


export default function ViewAndDownload({ data = {} }) {

    const { token, title, type, extension } = data;

    const [inProgress, setInProgress] = useState(false)

    const [downloded, setDownloded] = useState(false)


    const handleDownLoadFile = async () => {
        setInProgress(true)

        await download({ token, type, extension });

        setInProgress(false)
        setDownloded(true)
    }

    // State to store post content for "text" types
    const [showPostContent, setShowPostContent] = useState(false)
    const [postContent, setPostContent] = useState("")

    // for link,text types
    const handleOpenNormalItem = async () => {
        setInProgress(true)

        const { status, data } = await openNormalItem({ token, type });

        if (status) {

            if (data?.type === "link") {
                window.location.href = data?.content;
            }

            else if (data?.type === "text") {
                setShowPostContent(true)
                setPostContent(data?.content)
            }

        }

        setInProgress(false)
    }

    const [showPassForm, setShowPassForm] = useState(false)


    function createMarkup(txt) {
        if (txt) {
            const text = txt.replaceAll("\r", "<br />").replaceAll("\n", "<br />");
            return { __html: '<p>' + text + '</p>' }
        }
    };


    return (
        <>
            <div className="bg-white flex items-center justify-center min-h-screen px-2">
                <div className="max-w-2xl w-full py-10">
                    <div className="my-4">
                        <h1 className="lg:textHeader-xl textHeader-l">{title}</h1>
                    </div>

                    {!showPostContent ? (
                        <div className="lg:p-8 md:p-6 p-4 rounded-lg border w-full shadow-sm">

                        {
                            ['image', 'audio', 'document', 'video'].includes(type) ? (
                                <ToDownload
                                    itemData={data}
                                    inProgress={inProgress}
                                    setShowPassForm={setShowPassForm}
                                    handleDownLoadFile={handleDownLoadFile}
                                    downloded={downloded}
                                />
                            ) : ['text', 'link'].includes(type) ? (
                                <ToView
                                    itemData={data}
                                    inProgress={inProgress}
                                    setShowPassForm={setShowPassForm}
                                    handleOpenNormalItem={handleOpenNormalItem}
                                />
                            ) : null
                        }

                        {!isNull(data) ?
                        <div className="mt-16 rounded-md text-secondary/60 text-sm">
                            <h2 className="text-lg font-medium text-gray-700 mb-2">Security Tips</h2>
                            <ul className="list-disc list-inside space-y-3">
                                <li>Do not share this link or password with unauthorized persons.</li>
                                <li>Always verify the recipient's email before sending sensitive files.</li>
                                <li>Contact support immediately if you suspect a breach.</li>
                            </ul>
                        </div> : null}
                    </div>
                    ) : (
                        <div className="lg:py-4 md:py-3 py-2 lg:my-4 md:my-3 my-2 w-full border-t border-primary/[0.04]">
                            <div dangerouslySetInnerHTML={createMarkup(postContent)} />
                        </div>
                    )}
                </div>
            </div>

            {showPassForm ? (
                <PasswordForm
                    close={() => setShowPassForm(false)}
                    download={handleDownLoadFile}
                    handleOpenNormalItem={handleOpenNormalItem}
                    type={type}
                    token={token}
                />
            ) : null}
        </>
    )
}