import md5 from "md5";


export const hashRouter = (path = "") => {

    const hashedRouter = md5(path)

    const completeRouter = "api/" + hashedRouter;

    return completeRouter;
}