import { useEffect } from "react"
import PageLoader from "../components/PageLoader"

export default function Home() {


    useEffect(() => {
        const frontendUrl = process.env.REACT_APP_FRONTEND_URL;
        window.location.href = frontendUrl;
    }, [])

    return (
        <div className="">
            <PageLoader/>
        </div>
    )
}