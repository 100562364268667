import { LoaderIcon02, ReadIcon, VisitIcon } from "../../db/icons";



export default function ToView({ itemData, inProgress, setShowPassForm, handleOpenNormalItem }) {

    const { type, pass: needPass } = itemData ?? {};


    const buttonName = type === "link" ? "Visit link" : type === "text" ? "Read text" : "Submit";
    const boxTitle = type === "link" ? "This link is ready to visit" : type === "text" ? "This text is ready to read" : "#";

    return (
        <>
            {/* <!-- File Icon and Title --> */}
            <div className="text-center">
                {type === "text"
                    ? <ReadIcon className="w-24 h-w-24 text-gray-200 mx-auto" />
                    : <VisitIcon className="w-24 h-w-24 text-gray-200 mx-auto" />
                }
                <h1 className="textHeader-m text-gray-800 mt-4">{boxTitle}</h1>
            </div>

            {/* <!-- Download Button --> */}
            <div className="mt-8 text-center">
                <button
                    disabled={inProgress}
                    onClick={() => {
                        if (needPass) {
                            setShowPassForm(true)
                        } else {
                            handleOpenNormalItem()
                        }
                    }}
                    type="button"
                    className="w-full px-8 py-4 textBody-m2 bg-primary text-white rounded-full shadow-md hover:bg-primary/90 transition duration-300">
                    {inProgress ? <LoaderIcon02 className="w-6 h-6 mx-auto" /> : buttonName}
                </button>
            </div>
        </>
    )
}