import { DownloadIcon, LoaderIcon02 } from "../../db/icons";
import { formatFileSize } from "../../Helpers/uploadItems";



export default function ToDownload({ itemData, inProgress, setShowPassForm, handleDownLoadFile, downloded }) {

    const { type, extension, size, pass: needPass } = itemData ?? {};

    return !downloded ? (
        <>
            {/* <!-- File Icon and Title --> */}
            <div className="text-center">
                <DownloadIcon className="w-24 h-w-24 text-gray-200 mx-auto" />
                <h1 className="textHeader-m text-gray-800 mt-4">This file is ready to download</h1>
                <p className="text-gray-500 mt-2">File name: <span className="font-medium">{`${type}.${extension}`}</span></p>
                <p className="text-gray-500">Size: <span className="font-medium">{formatFileSize(size)}</span></p>
            </div>


            {/* <!-- Download Button --> */}
            <div className="mt-8 text-center">
                <button
                    disabled={inProgress}
                    onClick={() => {
                        if (needPass) {
                            setShowPassForm(true)
                        } else {
                            handleDownLoadFile()
                        }
                    }}
                    type="button"
                    className="w-full px-8 py-4 textBody-m2 bg-primary text-white rounded-full shadow-md hover:bg-primary/90 transition duration-300">
                    {inProgress ? <LoaderIcon02 className="w-6 h-6 mx-auto" /> : `Download Now`}
                </button>
            </div>
        </>
    ) : (
            <div className="text-center py-8">
                <div className="w-fit h-fit p-4 bg-green-200 rounded-full mx-auto shadow-sm animation-03">
                    <div className="w-fit h-fit p-4 bg-green-300 rounded-full">
                        <div className="w-fit h-fit p-4 bg-green-400 rounded-full text-white">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill={"none"}
                                className="w-14 h-14"
                            >
                                <path
                                    d="M5 14.5C5 14.5 6.5 14.5 8.5 18C8.5 18 14.0588 8.83333 19 7"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>

                <h2 class="textHeader-m text-gray-800 mt-4">File Downloaded Successfully!</h2>
                <p class="mt-2 text-gray-500">Your file has been downloaded.</p>
            </div>
        )
}