import { hashRouter } from "../Helpers/useHashRouter";
import axiosClient from "./axiosClient";


export const csrf = () => axiosClient.get("/sanctum/csrf-cookie");


export const download = async (params) => {
    if (!params) return;

    try {
        await csrf();
        const response = await axiosClient({
            url: hashRouter("downloadFile"),
            method: "GET",
            params: { token: params?.token },
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create file name
        const fileName = `${params?.type}.${params?.extension}`;

        const alink = document.createElement('a');
        alink.href = url;
        alink.setAttribute('download', fileName);
        document.body.appendChild(alink);
        alink.click();

        // Clean up
        alink.remove();
        window.URL.revokeObjectURL(url);

    } catch (error) {
        console.log(error)
    }
}